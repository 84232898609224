<template>
  <div class="Agreement">
    <div class="backHome" @click="goToLogin">Back<br>Home</div>
    <div class="Agreement-title">WorldPlan Logistics Inc.</div>
    <div class="Agreement-subtitle">User Terms of Service</div>
    <div class="Agreement-text">
      <h4>Composition and Instructions</h4>
      <p>1. Welcome to our products and service. Our products are composed of all clients applications of all versions. Websites, services, products and contents related to We are collectively referred to as “the Service” under User Terms of Service(“the Terms”). Individual Users and the companies that the Individual Users represent referred to as “you” or “your”.</p>
      <p>2. The Terms constitute a legal binding between you and us, which set forth terms and condtions by which you may register, log in and use the Service(collectively, “access or use”). By accessing or using the services, you confirm that you have full capacities for civil rights and civil conducts to perform the Terms; otherwise, you are not allowed to use the Services. Accessing or using the Service will be treated as fully accept the Terms and the further revisions.</p>
      <p>3. Prior to access or use the servcies provided by us, please take the time to read carefully and fully understand the Terms, especially the terms that exclude or limite liablities highlighted in bold for your special attention.</p>
    </div>
    <div class="Agreement-text">
      <h4>Your Access to and Use of Our Service</h4>
      <p>1. The Service must be configured and used properly, and at your own discretion, take applicable security measures to protect your contents.</p>
      <p>2. The Service is limited to providing to you for normal and legal business purpose. While accessing or using the Services, you are not allowed to：</p>
      <p>1）use the Service for business that violates the law;</p>
      <p>2）send information that endangers public security or which is illegal, false, harassing, insulting, threatening, harmful, destructive, provocative, obscene</p>
      <p>3）sublicense, sale or transfer of the Service to other third parties in any form;</p>
      <p>4）provide us with company or personal informaiton that is false or potentially misleading or even deceptive;</p>
      <p>5）abuse the communication feature of the Service to send “spam”, “junk mail”, “chain letters”, “pyramid schemes”without our consent or the consent of receivers, or any other prohibited content for solicitation;</p>
      <p>6）hick, disrupt, attack or steal the network date;</p>
      <p>If you use the Service to engage in the above behaviors, we have the right to, in our sole discretion, take appropriate disposal measures without notifying you in advance, including but not limited to forewarn, restrict, suspend and terminate your use of the whole or part of the Services, impose a ban onto the whole or part of account functions, freeze or permanently delete the account and terminate the Terms, any consequence and loss arising from which shall be at your own risk.</p>
      <p>3. We use the e-mail address corresponding to the Internet domain name you povided during resgistration as your unique identification information. of the user certificate. When you no longer have the right to use the email, we have the right to delete all data under the account corresponding to the email at any time.</p>
      <p>4. You must be equipped with the necessary equipment for accessing the Internet and using the telecom value-added services, and bear the communication fee, information fee and other related fees charged by the third party (including but not limited to telecom or mobile communication providers) for accessing the Internet. If it involves telecom value-added services, please confirm the relevant fees with your telecom value-added service provider.</p>
    </div>
    <div class="Agreement-text">
      <h4>Intellectual Property Right</h4>
      <p>1. You understand and agree that unless otherwise we specify or laws and regulations bestow rights upon the relevant right holders, all intellectual property rights (including but not limited to copyright, trademark right, patent right and business secrets) and related rights and interests in respect of such information contained in the Services as products, technology, software, programs and data, including but not limited to texts, pictures, audio clips, video clips, graphics, UI design, page frames, and related data or electronic files, belong to our property or the property of our affiliates. Without the written consent of us or related right holders, you may not use at will or grant any third-party licenses to use the contents mentioned above (including but not limited to monitor, copy, forward, spread, disseminate, play, show, sell, license, mirror, upload and download contents contained in the Services by means of any program or device like robots and web crawlers) for any commercial or non-commercial purposes.</p>
      <p>2. You fully own the the data and materials, including but not limited to: goods information, price data, customer information, contact information, etc. created when using the Service, and has the right to dispose of them in any form, including copying, exporting and deleting.</p>
      <p>3. You shall not use the contents related to the intellectual property rights owned by us for any commercial purposes outside the scope of this Terms or disclose them to a third party. Selling, reselling, copying , or imitating the Service for commercial purposes is not allowed. You can not copy and imitate the design concept, interface, function, text and graphic description of the Service without the permission of us. You shall not modify the platform or manufacture or derive other products based on the services agreed in this Terms.</p>
    </div>
    <div class="Agreement-text">
      <h4>Privacy and Security of Data</h4>
      <p>1. Data backup: on the basis of near real-time backup, we ensure that the data is fully backed up at least once a day, and the incremental backup is at least three times a day. Monthly backup is reserved for one year, daily backup for one week, and incremental backup for one day. Every day, we make remote backup for the data information stored on the server of us, and complete the redundancy prevention mechanism, so as to ensure that the system can be quickly restored to normal operation after a problem occurs in the system.</p>
      <p>2. You have the obligation to maintain the integrity of your account information and the confidentiality of your password. You shall be responsible for the loss caused by the leakage of relevant information due to the your poor maintenance or confidentiality, or by the third party embezzlement of the your account and password for various operations. You are not allowed to provide all or part of the Service to others, or allow others to use the Service through your own account.</p>
      <p>3. We do not accept oral request from you to reset the password. We don’t know the password you setup after initialization. We will not take any action which will invade privacy.</p>
      <p>4. You are solely responsible for the accuracy, reliability, legality, and applicability of the input data. You must not violate the security or authorization measures of us, and may not read or access data that is not yours.</p>
      <p>5. The confidentiality obligations, rights and responsibilities in this Terms shall not apply to the following:</p>
      <p>1）information that is already known to the public not due to improper reasons of us;</p>
      <p>2）information that is obtained by other channels which are not limited by the confidentiality obligations;</p>
      <p>3）information released with the authorization of users;</p>
      <p>4）information disclosed at the request of the law, the court or other competent authorities of the state.</p>
      <p>6. We reserve the right to use summary statistics, which are anonymous and not user-specific.</p>
      <p>7. We reserve the right to push advertisement to you, the loading process for these advertisement may apply necessary directional technology to improve the relevance. But we will not disclose any private information of you to advertiser, only realize the high relevance by apply automatic matching technology on anonymous basis.</p>
    </div>
    <div class="Agreement-text">
      <h4>Service Level and Compensation</h4>
      <p>1. We will try our best to ensure the continuous and reliable Service and we shall inform you one week in advance of any planned downtime upgrade or maintenance. This downtime is not counted as a percentage of uptime availability. If you have different opinions on the upgrade and maintenance downtime, you need to make a request within the next day after receiving the notice and negotiate with us.</p>
      <p>2. We will do our best to ensure the safe backup of your data. We promise to restore data from the latest backup as quickly as possible when any data is damaged by the server storage device. However, we cannot promise 100% data recovery. We do not assume any responsibility for other joint or indirect losses caused by data loss.</p>
      <p>3. We will need 4~48 hours to recover the data from the backup file if it is necessary. Recovering data during this period will be treated as continual service.</p>
    </div>
    <div class="Agreement-text">
      <h4>Force majeure and Exceptions</h4>
      <p>1. Service interruption and long-term service termination caused by uncontrollable reasons will not treated as breaching the Terms and We will not take the responsibility of compensation, including but not limited to:</p>
      <p>1）Hardware and network of customer terminal, Internet and power supply system of machine room of you are in failure;</p>
      <p>2）Force Majeure: earthquake, typhoon, flood and other natural disasters; war, strike, blackout, government behavior; behavior of government power department or telecommunication department; telecommunication line is artificially damaged or interruption of backbone network line and equipment due to debugging and expansion; Internet public network blocking or other access (such as response speed of server accessing destination) problems; It is caused by external man-made destructive factors and exceeds the bearing capacity of safety equipment;</p>
      <p>3）Other unforeseen, unavoidable, insurmountable and uncontrollable force majeure. The party suffering from the force majeure shall take all necessary measures to reduce the loss and negotiate to resume the performance of the contract after the event is eliminated.</p>
      <p>2. We do not guarantee the accuracy, reliability or integrity of the public information, software demonstration and free software service content contained in the website, and explicitly denies responsibility for errors or omissions in such information and software functions. it does not make any kind of guarantee for the information and free software, including but not limited to no infringement of third party rights, ownership and merchantability The security, quality, suitability for specific use and no computer virus guarantee.</p>
      <p>3. We shall not be responsible for any damages, whether caused by the use of or inability to use the Service or by any website connected to the platform or the information contained in any such site (including but not limited to damages caused by lost profits, lost data or business interruption), and whether they are guaranteed, contracted or infringed Act or any other legal basis and prior information that such damage may occur. If the user needs to maintain, repair or correct the equipment or data due to using the free information and free software version of the website, the user shall bear all the expenses incurred.</p>
      <p>4. Under any circumstance, we shall deal with any direct, indirect, special, joint or other losses (including but not limited to any loss of profit, business interruption, and loss of information or programs or other data in your information processing system) shall not be liable to any organization.</p>
      <p>5.All the comments on our website, forums and blogs do not represent the views of us. The comments mentioned here include but not limited to news and dynamics. If above mentioned comments is harmful to you, we will assist you and relevant departments to clean relevant content and find the IP who published these article based on the legal requirement, and we will not assume any responsibility.</p>
    </div>
    <div class="Agreement-text">
      <h4>Amendment of Terms</h4>
      <p>We reserve the right to revise the Terms at any time, and will publish these revised terms on our website three days before it takes effect.</p>
    </div>
    <div class="Agreement-text">
      <h4>Dispute Resolution</h4>
      <p>1. The Terms are applicable to the laws of the People Republic of China and the place of signing the Terms is Shanghai, China.</p>
      <p>2. In case of any dispute over the interpretation or implementation of this Terms, both parties shall first strive to resolve the dispute through friendly negotiation. If one party fails to settle the dispute through negotiation within 60 days after serving a written notice to the other party requesting the commencement of negotiation, then either party may submit the dispute to Shanghai International Arbitrattion for arbitration in accordance with its then effective rules. The arbitration award is final and binding on all parties and is not subject to appeal. The arbitration fee shall be borne by the unsuccessful party, unless otherwise specified in the arbitration award. In the event of any dispute and in the arbitration of any dispute, except for the items in dispute, each party shall continue to exercise its other rights and perform its other obligations under this agreement.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreement',
  data() {
    return {

    }
  },

  methods: {
    goToLogin() {
      this.$router.push({
        path: '/Login'
      })
    }
  }
}
</script>

<style scoped lang="less">
.Agreement {
  width: 1260px;
  background: #fff;
  margin: 0 auto;

  .backHome {
    position: fixed;
    right: 15px;
    bottom: 100px;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    font-size: 14px;
    background: #0081ff;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
    &:hover {
      cursor: pointer;
    }
  }

  .Agreement-title {
    padding: 20px 0;
    color: #0081ff;
    text-align: center;
    font-size: 26px;
    font-weight: 550;
    border-bottom: 1px solid #eee;
  }

  .Agreement-subtitle {
    padding: 15px 0;
    color: #181818;
    text-align: center;
    font-size: 22px;
    font-weight: 550;
  }

  .Agreement-text {
    padding: 0 30px 20px 30px;
    margin-bottom: 10px;

    h4 {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 550;
      color: #181818;
    }

    p {
      margin-top: 4px;
      font-size: 13px;
      line-height: 25px;
      color: #4b4b4b;
    }
  }
}
</style>